import { mapState } from 'vuex'

export default {
  name: 'SavedGoogles',
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapState({
      currency: (state) => ({...state.currency.allCurrency[state.currency.selected]})
    })
  },
  methods: {
    getPriceWithVat (price) {
      return this.$parent.getPriceWithVat(price)
    },
    reverseRouteName (str, defaultName = 'route-name') {
      return this.$parent.reverseRouteName(str, defaultName)
    }
  }
}
